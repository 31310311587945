<template>
  <div class="breadcrumb-block">
    <a-breadcrumb>
      <a-breadcrumb-item> <router-link to='/item-foods'>Home</router-link> </a-breadcrumb-item>
      <a-breadcrumb-item v-for="(bread, index) in listBreadcrumb" :key="index"><router-link :to="bread.path">{{bread.label}}</router-link></a-breadcrumb-item>
    </a-breadcrumb>
  </div>
</template>

<script>
export default {
  name: "Breadcrumb",
  computed: {
    listBreadcrumb() {
      return this.$store.getters['breadcrumb']
    }
  }
}
</script>

<style scoped lang='scss'>
.breadcrumb-block {
  ::v-deep .ant-breadcrumb-separator {
    color: #ffffff !important;
  }
  a, span {
    color: #ffffff !important;
  }
  a {
    &:hover {
      color: #ffffff;
    }
  }
}
</style>
